import { useState } from 'react'
import { IconArrowLeft, IconArrowRight } from '@tabler/icons-react'
import classNames from 'classnames'
import type { Swiper as SwiperType } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import { ArticleCard, Button, Link, ProjectCard } from '~/components'
import { SectionContainer } from '~/components/SectionContainer'
import Typography, {
  TypographyTag,
  TypographyVariant,
} from '~/components/Typography'
import { usePage } from '~/hooks'
import styles from './CardsCarousel.module.css'
import { CardsCarouselProps } from './CardsCarousel.types'

export const CardsCarousel = ({
  width = 'full',
  title,
  paragraph,
  viewAllUrl,
  backgroundColor,
  cards,
}: CardsCarouselProps) => {
  const [swiper, setSwiper] = useState<SwiperType | null>(null)
  const [reached, setReached] = useState<[boolean, boolean]>([true, false])
  const page = usePage()
  const isRTM = page?.brand === 'rtm'

  const handleSwip = (dir: string) => {
    if (!swiper) {
      return
    }

    if (dir === 'prev') {
      swiper.slidePrev()
    } else {
      swiper.slideNext()
    }
  }

  const handleSlide = (instance: SwiperType) => {
    setReached([instance.isBeginning, instance.isEnd])
  }

  const isArticleCard = cards.some((card) => {
    return card.cardType === 'article-card'
  })

  return (
    <SectionContainer width={width}>
      <div
        className={classNames(
          `grid grid-cols-1 lg:grid-cols-4 lg:gap-4 px-4 ${
            isRTM ? 'py-10 gap-5' : 'py-12 gap-6'
          } lg:p-20`,
          backgroundColor === 'dark' && 'bg-background-thumb'
        )}>
        <div
          className={classNames(
            'lg:flex flex-col justify-center',
            styles.titleContainer
          )}>
          <Typography
            tag={TypographyTag.p}
            variant={
              isArticleCard
                ? TypographyVariant.Heading4
                : TypographyVariant.Heading5
            }
            className={classNames(
              'block',
              isArticleCard ? styles.articleCardTitle : styles.projectCardTitle
            )}>
            {title}
          </Typography>
          <div className='mt-2'>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularExtraLineHeight}
              className='tracking-normal'>
              {paragraph}
            </Typography>
          </div>
          <Link
            href={viewAllUrl}
            className={classNames(
              styles.linkBtn,
              'hidden lg:flex items-center w-fit gap-2.5 px-4 py-3.5 my-6 rounded bg-white',
              isRTM && 'mb-16'
            )}>
            <Typography
              tag={TypographyTag.span}
              variant={TypographyVariant.BodyRegularBold}>
              View all articles
            </Typography>
            <IconArrowRight size={20} />
          </Link>
          <div className='hidden lg:flex items-center gap-4'>
            <Button
              theme={{ root: classNames(styles.controlBtn, '') }}
              shape='circle'
              iconOnly
              disabled={reached[0]}
              onClick={() => {
                return handleSwip('prev')
              }}>
              <IconArrowLeft />
            </Button>
            <Button
              theme={{ root: classNames(styles.controlBtn, '') }}
              shape='circle'
              iconOnly
              disabled={reached[1]}
              onClick={() => {
                return handleSwip('next')
              }}>
              <IconArrowRight />
            </Button>
          </div>
        </div>
        <div className='col-span-1 lg:col-span-3 -mr-4 lg:-mr-20'>
          <Swiper
            onSwiper={(instance) => {
              setSwiper(instance)
            }}
            init={false}
            slidesPerView={1.4}
            spaceBetween={16}
            breakpoints={{
              '1080': {
                slidesPerView: 3.2,
              },
              '1440': {
                slidesPerView: 3.2,
              },
            }}
            onReachBeginning={handleSlide}
            onReachEnd={handleSlide}>
            {cards?.map((card, index) => {
              return (
                <SwiperSlide key={index}>
                  {card.cardType === 'article-card' ? (
                    <ArticleCard {...card} />
                  ) : (
                    <ProjectCard {...card} />
                  )}
                </SwiperSlide>
              )
            })}
          </Swiper>
        </div>
        <div className='lg:hidden'>
          <Button
            status='secondary'
            theme={{
              root: classNames(styles.linkBtn, styles.bottomBtn, 'w-full'),
            }}>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularBold}>
              View all articles
            </Typography>
          </Button>
        </div>
      </div>
    </SectionContainer>
  )
}
