import { VisuallyHidden } from '@overdose/components'
import classNames from 'classnames'
import Link from 'next/link'
import { Image } from '~/components/Image'
import RichText from '../RichText/RichText'
import Typography, { TypographyTag, TypographyVariant } from '../Typography'
import styles from './ArticleCard.module.css'
import { ArticleCardProps } from './ArticleCard.types'

export const ArticleCard = ({
  title,
  articlePreview,
  isFeaturedArticle,
  publishedAt,
  timeToRead,
  image,
  tag,
  url,
  imagePosition = 'top',
  hideReadmore = false,
  imageClassname = '',
  tagPosition = 'top-left',
  tagColor,
  addSrcSet = true,
}: // eslint-disable-next-line sonarjs/cognitive-complexity
ArticleCardProps) => {
  const tagColorStyle =
    tagColor === 'primary'
      ? 'bg-background-primary-muted'
      : 'bg-state-success-light'

  return (
    <div
      className={classNames(
        'flex rounded-md overflow-hidden gap-4 mx-auto w-full',
        styles.root,
        {
          'flex-row lg:items-center lg:gap-x-8': imagePosition === 'left',
          'flex-col': imagePosition === 'top',
          'flex-col lg:flex-row lg:gap-x-0 lg:items-stretch lg:grid lg:grid-cols-12':
            isFeaturedArticle,
          '!items-start !gap-4': imagePosition === 'left' && hideReadmore,
        }
      )}>
      {image?.src && (
        <div
          className={classNames(
            'relative rounded-md overflow-hidden',
            !isFeaturedArticle && 'aspect-square',
            {
              [styles.topImage]: !isFeaturedArticle && imagePosition === 'top',
              [styles.leftImage]:
                !isFeaturedArticle && imagePosition === 'left',
              'max-lg:w-full aspect-video lg:col-span-7 3xl:lg:col-span-6 3xl:col-start-2 lg:row-span-full':
                isFeaturedArticle,
            },
            imageClassname
          )}>
          <Image
            src={image.src}
            width={image.width}
            height={image.height}
            className='w-full h-full'
            alt={image.altText}
            style={{ objectFit: 'cover' }}
            addSrcSet={addSrcSet}
          />
          {tag && tagPosition === 'top-left' && (
            <div
              className={classNames(
                styles.tag,
                tagColorStyle,
                'absolute top-4 left-4  md:top-6 md:left-6 z-10 p-2 w-max'
              )}>
              <Typography
                tag={TypographyTag.p}
                variant={TypographyVariant.BodySmallBold}
                className={styles.tag}>
                {tag}
              </Typography>
            </div>
          )}
        </div>
      )}
      <div
        className={classNames('flex flex-col flex-1 justify-center', {
          '!px-0 lg:!px-6 3xl:!p-8 !justify-center lg:col-span-5 lg:row-span-full 3xl:col-span-4':
            isFeaturedArticle,
          'px-0': imagePosition === 'left',
          '!justify-start': imagePosition === 'top',
        })}>
        {tag && tagPosition === 'below-image' && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodySmallBold}
            className={classNames(
              'text-white',
              styles.tag,
              tagColorStyle,
              'w-max mb-4 py-1 px-2'
            )}>
            {tag}
          </Typography>
        )}
        {publishedAt && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegular}
            className={classNames(
              'flex flex-wrap gap-x-2 mb-2 items-start',
              styles.dateTime,
              {
                'h-8 sm:h-4': imagePosition === 'top' && !isFeaturedArticle,
              }
            )}>
            <span className='block'>
              {new Date(publishedAt).toLocaleDateString('en-AU', {
                day: '2-digit',
                month: 'long',
                year: 'numeric',
              })}
            </span>
            {!!timeToRead && (
              <>
                <span
                  className={classNames('hidden md:block', {
                    '!block': imagePosition === 'left' || isFeaturedArticle,
                  })}>
                  •
                </span>
                <span className='block'>{timeToRead} mins read</span>
              </>
            )}
          </Typography>
        )}

        <Typography
          tag={TypographyTag.p}
          variant={TypographyVariant.BodyLargeBold}
          className={classNames('line-clamp-4 text-primary-heading', {
            '!line-clamp-3': isFeaturedArticle,
            [styles.featuredArticleTitle]: isFeaturedArticle,
            [styles.imageLeftTitle]: imagePosition === 'left',
            'flex-1': imagePosition === 'top' && !isFeaturedArticle,
          })}>
          {title}
        </Typography>
        {articlePreview && (
          <Typography
            tag={TypographyTag.p}
            variant={TypographyVariant.BodyRegularExtraLineHeight}
            className={classNames('mt-4', {
              'line-clamp-5': isFeaturedArticle,
              'line-clamp-3': imagePosition === 'top',
              hidden: imagePosition === 'left',
            })}>
            {articlePreview?.content
              ? articlePreview.content?.map((block, index) => {
                  return <RichText key={index} content={block.richText} />
                })
              : articlePreview.excert}
          </Typography>
        )}

        {!hideReadmore && (
          <Link
            title={`Read more about ${title}`}
            href={url}
            className={classNames('underline w-fit', {
              '!mt-4': isFeaturedArticle,
              'mt-6': imagePosition === 'top',
              'mt-1 md:mt-4': imagePosition === 'left',
            })}>
            <Typography
              tag={TypographyTag.p}
              variant={TypographyVariant.BodyRegularExtraLineHeight}
              className={styles.readMore}>
              Read more
            </Typography>
            <VisuallyHidden>about {title}</VisuallyHidden>
          </Link>
        )}
      </div>
    </div>
  )
}
